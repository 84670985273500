<template>
  <div class="animated fadeIn">
    <CardTable :title="title"
               :columns="table.columns"
               :rows="table.rows"
               :isLoading="table.isLoading"
               :pageSize="serverParams.pageSize"
               :pageNumber="serverParams.pageNumber"
               :totalPage="table.totalPage"
               :totalRecords="table.totalRecords"
               @onRowSelected="onRowSelected"
               @onPageChange="onPageChange">
      <template #action>
        <div class="card-header-actions">
          <b-button :to="{ name: 'MeterReadingsDeleteAll' }"
                    size="sm"
                    variant="primary">
            Delete All
          </b-button>
          <span style="margin-left: 10px">
            <b-button :to="{ name: 'MeterReadingsImport' }"
                      size="sm"
                      variant="primary">
              Import Data
            </b-button>
          </span>
          <span style="margin-left: 10px">
            <b-button @click="exportAll"
                      size="sm"
                      variant="primary">
              Export
            </b-button>
          </span>
        </div>
      </template>
      <template #filter>
        <SectionFilter :serverParams="serverParams" @onChange="getAll" />
      </template>
      <template #cell(period)="data">
        {{ `${parseMonth(data.item.readingMonth)}/${data.item.readingYear}` }}
      </template>
      <template #cell(currentReadingKwh)="data">
        {{ numberFormat(data.item.currentReadingKwh) }}
      </template>
      <template #cell(currentUsageKwh)="data">
        {{ numberFormat(data.item.currentUsageKwh) }}
      </template>
      <template #cell(currentReadingDate)="data">
        {{ dateFormat(data.item.currentReadingDate) }}
      </template>
      <template #cell(action)="data">
        <b-button class="ml-4" @click="handleDelete(data.item)" size="sm">Delete</b-button>
      </template>
    </CardTable>
  </div>
</template>

<script>
  import { parseMonth, dateFormat, numberFormat } from "@/shared/utils";
  import { meterReadings as columns } from "@/shared/columns";
  import SectionFilter from "./SectionFilter.vue";

  export default {
    components: {
      SectionFilter,
    },
    data: () => ({
      title: "Meter Readings",
      serverParams: {
        pageNumber: 1,
        pageSize: 500,
        keyword: null,
        name: null,
        serialNo: null,
        email: null,
        period: null,
        // orderBy: "readingYear desc,readingMonth desc",
      },
      table: {
        isLoading: true,
        columns,
        rows: [],
        totalPage: 0,
        totalRecords: 0,
      },
    }),
    created() {
      const self = this;
      self.getAll();
    },
    methods: {
      parseMonth,
      numberFormat,
      dateFormat,
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      onRowSelected(items) {
        const self = this;
        self.$router.push({
          name: "MeterReadingsHistory",
          params: {
            id: items[0].serialNo,
          },
        });
      },
      onPageChange(params) {
        this.updateParams({ pageNumber: params });
        this.getAll();
      },
      handleDelete(item) {
        const self = this;
        let _confirmText =
          "You are about to delete the meter readings. This will delete all the associated data like payments as well. Are you sure ?",
          _okText = "Yes, Delete",
          _action = "apis/remove",
          _url = "/MeterReading/" + item.id;

        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  this.getAll();
                }
              });
          });
      },
      getAll() {
        const self = this;

        self.table.isLoading = true;
        self.$store
          .dispatch("apis/get", {
            url: "/meterreading",
            params: self.serverParams,
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              self.table.rows = response.data.data;
              self.table.totalPage = response.data.totalPage;
              self.table.totalRecords = response.data.totalData;
            }
            self.table.isLoading = false;
          });
      },
      exportAll() {
        const self = this;
        self.$store
          .dispatch("apis/download", {
            url: "/MeterReading/export",
            params: self.serverParams,
          })
          .then((response) => {
            console.log(response)
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });
            } else {
              let fileURL = window.URL.createObjectURL(new Blob([response])),
                fileLink = document.createElement("a");

              fileLink.href = fileURL;
              fileLink.setAttribute("download", "meter-reading.xls");
              document.body.appendChild(fileLink);

              fileLink.click();
            }
          });
      },
    },
  };
</script>
